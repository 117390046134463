import { useState, useEffect } from 'react';

import ModalDialog from 'elements/modal/ModalDialog';

import globalConfig from 'config/globalConfig.js'
import { getValueFromParameterMap } from 'cms/NBossCMS.js';

export default function ModalImportantMessage(props) {
    const [active, setActive] = useState(false);
    const [visible, setVisible] = useState(false);

    let _data = {
        startDate: getValueFromParameterMap(props.data, 'megjelenes-kezdete'),
        endDate: getValueFromParameterMap(props.data, 'megjelenes-vege'),
        title: getValueFromParameterMap(props.data, 'title'),
        content: getValueFromParameterMap(props.data, 'content'),
        buttonLabel: getValueFromParameterMap(props.data, 'button-label'),
        session: getValueFromParameterMap(props.data, 'session'),
    };

    useEffect(() => {
        if (props.data) {
            let dateOk = false;
            let sessionOk = false;

            const currentDate = new Date();
            currentDate.setHours(12, 0, 0, 0);

            let startDate = null;
            if (_data.startDate) {
                startDate = new Date(_data.startDate);
                startDate.setHours(0, 0, 0, 0);
            }
            let endDate = null;
            if (_data.endDate) {
                endDate = new Date(_data.endDate);
                endDate.setHours(0, 0, 0, 0);
            }
            if ((!endDate && !startDate) || (!endDate && currentDate >= startDate) || (currentDate >= startDate && currentDate <= endDate))
                dateOk = true;

            console.log(currentDate > startDate && currentDate < endDate);
            console.log(currentDate);
            console.log(startDate);
            console.log(endDate);

            if (_data.session)
                sessionOk = localStorage.getItem(globalConfig.etc.cookiePerfix + '-popupSession-' + props.data.key) ? false : true;
            else
                sessionOk = true;

            console.log(dateOk + '-' + sessionOk)

            if (dateOk && sessionOk)
                setActive(true);
        }
    }, [_data.endDate, _data.startDate, _data.session, props.data]);

    useEffect(() => {
        if (active)
            setVisible(true);
    }, [active]);

    if (active && visible)
        return (<ModalDialog title={_data.title} content={_data.content} buttonLabel={_data.buttonLabel} onClose={() => { setVisible(false); localStorage.setItem((globalConfig.etc.cookiePerfix + '-popupSession-' + props.data.key), Date.now()); }} />);
    else
        return null;
}