import Content from 'components/Content.js';
import React, { useState } from 'react';

import { NBossCMSPage } from 'cms/NBossCMS.js';

export default function SimplePage(props) {
    const [page, setPage] = useState(null);

    return (
        <NBossCMSPage pageprops={props} pageDataReady={page => { setPage(page) }} className={'w-full flex-1 flex flex-col items-center'}>
            {page && <Content data={page.parameterMap['content'].value} />}
        </NBossCMSPage>
    );
}